import * as React from "react"

import {
  ContactUsEmailLink,
  ContactUsPhoneLink,
  Heading,
  ReadMoreLink,
  TickListItem,
  YouTubeVideo,
  media
} from "@life-without-barriers/react-components"

import styled from "styled-components"

export interface ContactUsItemProps {
  data: string
  type: "phone" | "phoneOOH" | "email"
}

export interface ProgramProps {
  title: string
  location: string
  paragraphs: string[]
  checklist?: string[]
  secondParagraph?: string
  youTubeVideo?: string
  contactUs: ContactUsItemProps[]
  resourceLink: string
  resourceLinkText?: string
}

const ContactUsItem = ({ data, type }: ContactUsItemProps) => {
  const LinkElement =
    type === "email" ? (
      <ContactUsEmailLink text={data} link={`mailto:${data}`} />
    ) : (
      <ContactUsPhoneLink phoneNumber={data} outOfHours={type === "phoneOOH"} />
    )

  return <div className="w-100 w-30-ns">{LinkElement}</div>
}

const StyledList = styled.ul`
  ${media.notSmall`
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  `}
`

const Program = ({
  title,
  location,
  paragraphs,
  checklist,
  secondParagraph,
  contactUs,
  youTubeVideo,
  resourceLink,
  resourceLinkText
}: ProgramProps) => {
  return (
    <>
      <Heading size={3}>{`${title} - ${location}`}</Heading>
      {paragraphs.map((text, i) => (
        <p key={i}>{text}</p>
      ))}
      {checklist && (
        <div className="flex flex-row flex-wrap">
          <StyledList className="pa0 w-100">
            {checklist.map((text, i) => (
              <TickListItem className="pv3 w-90" text={text} key={i} />
            ))}
          </StyledList>
        </div>
      )}
      {secondParagraph && <div className="copy">{secondParagraph}</div>}
      <div className="flex-ns">
        {contactUs.map((item, i) => (
          <ContactUsItem key={i} data={item.data} type={item.type} />
        ))}
      </div>
      <ReadMoreLink
        text={resourceLinkText ?? `${title} - Brochure`}
        href={resourceLink}
        className="pv4 pr4-l"
      />
      {youTubeVideo && (
        <YouTubeVideo className="mt4" videoId={youTubeVideo} title={title} />
      )}
    </>
  )
}
export default Program
