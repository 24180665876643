import {
  Banner,
  Box,
  CardInline,
  Container,
  Heading,
  Helmet,
  Interfaces,
  Layout,
  PreStyledComponents,
  Row,
  Section,
  Theme,
  routesObject
} from "@life-without-barriers/react-components"

import { IGatsbyImageData } from "gatsby-plugin-image"
import Program from "../../../components/services/homelessness/Program"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"

const { GridWrapper } = PreStyledComponents

const { corporateThemeFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    mentalHealthWANgattiHouseBrochure: Interfaces.FileNode
    ngattiHouseImage: IGatsbyImageData
    aryaHouseBrochure: Interfaces.FileNode
    aryaHouseImage: IGatsbyImageData
  }
}

const IndexPage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    mentalHealthWANgattiHouseBrochure,
    ngattiHouseImage,
    aryaHouseBrochure,
    aryaHouseImage
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Homelessness Support Services | ${siteMetadata.title}`}
        description={`Our homelessness support services in Western Australia 
          aim to assist the people we support to connect with a wider service network and address issues that 
          contribute to homelessness.`}
        image={bannerImage}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        themeColor={corporateThemeFull.medium}
      />
      <Layout theme={corporateThemeFull}>
        <Banner
          title="Homelessness services"
          image={bannerImage}
          subTitle="Our homelessness support services in Western Australia aim to assist the people we support to connect with a wider service network and address issues that contribute to homelessness."
          breadcrumbs={[routesObject.home, routesObject.service]}
        />
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>
                About our homelessness support services
              </Heading>
              <p>
                With many people doing it tough, we&apos;re doing everything we
                can to provide support and find accommodation for people who are
                homeless or at risk of being homeless.
              </p>
              <p>
                We know how easy it is to end up homeless - many people across
                our neighbourhoods do not know where they will be sleeping
                tonight.
              </p>
              <p>
                There are many factors that can contribute to homelessness.
                Domestic and family violence place many people at risk of
                homelessness, as do financial problems, unemployment and mental
                illness.
              </p>
              <p>
                Our services include assertive outreach, intensive case
                management support and residential programs.
              </p>
              <p>
                These services aim to help you to re-establish connections that
                will assist you to find somewhere to stay while taking steps to
                find a permanent place to call home, so you can get on with your
                life.
              </p>
            </Box>
          </Row>
          <Row topMargin="standard">
            <Box>
              <Heading size={2}>
                Western Australian Homelessness Services
              </Heading>
              <Program
                title="ARYA House: At Risk Youth Accommodation"
                location="Metro, WA"
                paragraphs={[
                  `
                  ARYA house is a safe environment that provides overnight accommodation for up to 6 young people 
                  between the ages of 14 and 17 years, two nutritious meals per day, snacks and laundry services. 
                  At least two full-time, skilled staff per shift operate the service between the hours of 5pm and 10am daily.`,
                  "The ARYA program provides:"
                ]}
                checklist={[
                  "Overnight accommodation when no alternative suitable accommodation is available",
                  "A nutritious breakfast and evening meal",
                  "The opportunity and means to maintain high standards of personal hygiene and care",
                  "Passes to use public transport as required."
                ]}
                resourceLink={aryaHouseBrochure.publicURL}
                contactUs={[
                  {
                    data: "0892778749",
                    type: "phone"
                  }
                ]}
              />
              <Program
                title="Ngatti House"
                location="Metro, WA"
                paragraphs={[
                  `Ngatti House is a residential program based in Fremantle and run by Life Without Barriers. 
                  It provides community-based homeless youth services to young people between 17 and 22 years of age, who show signs 
                  and symptoms of mental illness while homeless or at risk of homelessness.`,
                  `The service provides medium-term accommodation and clinical support, and has a strong focus on overcoming social 
                  disadvantage and enhancing social inclusion. Since its opening in 2010, Ngatti House has received an 
                  average of 37 referrals per year to house young people and assist them in overcoming social barriers.`,
                  `The service not only focuses on health issues but is also culturally appropriate and based on building self-esteem, 
                  social inclusion, recovery and the capacity for young people to achieve their desired goals whilst being supported by 
                  their local community.`
                ]}
                youTubeVideo="3a4Enn6h3Lk"
                resourceLink={mentalHealthWANgattiHouseBrochure.publicURL}
                contactUs={[
                  {
                    data: "0894321100",
                    type: "phone"
                  }
                ]}
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-hex-image">
          <Container>
            <Heading size={2}>Resources</Heading>
            <GridWrapper className="mt4 flex-ns flex-wrap-ns">
              <CardInline
                title="ARYA House: At Risk Youth Accommodation - Metro, WA"
                copy="ARYA house is a safe environment that provides overnight accommodation for up to 6 young people between the ages of 14 and 17 years, provides two nutritious meals per day, snacks and laundry services."
                image={aryaHouseImage}
                imageObjectFit="cover"
                href={aryaHouseBrochure.publicURL}
                ga={{
                  eventCategory: "Homelessness Support",
                  eventLabel:
                    "ARYA House: At Risk Youth Accommodation - Metro, WA"
                }}
              />
              <CardInline
                title="Ngatti House - Metro, WA"
                copy="Ngatti House provides community-based homeless youth services to young people between 17 and 22 years of age, who show signs and symptoms of mental illness while homeless or at risk of homelessness."
                image={ngattiHouseImage}
                imageObjectFit="cover"
                href={mentalHealthWANgattiHouseBrochure.publicURL}
                ga={{
                  eventCategory: "Homelessness Support",
                  eventLabel: "Ngatti House - Metro, WA"
                }}
              />
            </GridWrapper>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const fileNode = graphql`
  fragment FileNodeQuery on File {
    name
    publicURL
  }
`

export const query = graphql`
  {
    bannerImage: file(relativePath: { regex: "/homelessness-banner.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, layout: CONSTRAINED)
      }
    }
    mentalHealthWANgattiHouseBrochure: file(
      name: { eq: "MH.NAT.ICLS.6pp.DL.Ngatti_House.24" }
    ) {
      ...FileNodeQuery
    }
    ngattiHouseImage: file(relativePath: { regex: "/ngatti-house.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, layout: CONSTRAINED)
      }
    }
    aryaHouseBrochure: file(name: { eq: "MH.WA.ARYA-House-Metro-WA.24" }) {
      ...FileNodeQuery
    }
    aryaHouseImage: file(relativePath: { regex: "/arya-house.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, layout: CONSTRAINED)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
export default IndexPage
